<template>
  <div class="component-wrap">
    <plan
      v-if="currView == 'plan'"
      :taskInfo="currTask"
      @back="currView = 'manage'"
    />
    <evaluate
      v-else-if="currView == 'evaluate'"
      :taskInfo="currTask"
      @back="currView = 'manage'"
    />
    <sum
      v-else-if="currView == 'sum'"
      :taskInfo="currTask"
      @back="currView = 'manage'"
    />
    <member
      v-else-if="currView2 == 'member'"
      :taskInfo="currTask2"
      @back="currView2 = 'track'"
    />
    <div v-else>
      <a-tabs class="mine-tabs" v-model:activeKey="activeKey">
        <!-- 帮带任务 -->
        <a-tab-pane key="1" :tab="$t('teaching.helping_task')">
          <div>
            <manage v-if="currView == 'manage'" :detailId="detailId" :taskId="taskId" @handle="manageHandle" />
          </div>
        </a-tab-pane>
        <!-- 学习跟踪 -->
        <a-tab-pane key="2" :tab="$t('teaching.learning_track')">
          <div>
            <trackTable v-if="currView2 == 'track'" @handle="trackHandle" />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import manage from "./teaching/manage.vue";
import plan from "./teaching/plan.vue";
import { useRoute } from "vue-router";
import evaluate from "./teaching/evaluate.vue";
import sum from "./teaching/sum.vue";
import trackTable from "./teaching/track.vue";
import member from "./teaching/member.vue";
export default {
  components: {
    manage,
    plan,
    evaluate,
    sum,
    trackTable,
    member,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      activeKey: "1",
      currTask: null,
      currView: "manage",
      currTask2: null,
      currView2: "track",
      name:route.query.name ? route.query.name : "",
      detailId:route.query.detailId ? route.query.detailId : 0,
      taskId:route.query.taskId ? route.query.taskId : 0,
    });

    const manageHandle = (type, data) => {
      state.currView = type;
      state.currTask = data;
    };

    const trackHandle = (type, data) => {
      state.currView2 = type;
      state.currTask2 = data;
    };

    return {
      ...toRefs(state),
      manageHandle,
      trackHandle,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
